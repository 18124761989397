import * as Styled from "./styled"
import { ReactComponent as DragDrop } from "../../../icons/drag-drop.svg"
import { ReactComponent as CheckAll } from "../../../icons/checkAll.svg"
import { ReactComponent as CheckSome } from "../../../icons/checkSome.svg"
import React from "react"

const FirstCell = React.forwardRef(({ rowIndex, selected, onSelect }, ref) => {
  return (
    <Styled.Cell ref={ref}>
      <Styled.EmptyDiv>{rowIndex > -1 && <DragDrop />}</Styled.EmptyDiv>
      <Styled.Checkbox selected={selected} onClick={onSelect}>
        {selected === 1 ? <CheckAll /> : selected === 2 ? <CheckSome /> : null}
      </Styled.Checkbox>
      <Styled.Num>{rowIndex === -1 ? "N" : rowIndex + 1}</Styled.Num>
    </Styled.Cell>
  )
})

export default FirstCell
