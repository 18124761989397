import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { calculateRowWidth, getLastVisibleChildIndex } from "../../tables/helpers"
import * as Styled from "./styled"
import { selectTableData } from "../../tables/tablesSlice"
import { useTheme } from "styled-components"
import { selectSelectionData } from "../selectionSlice"
import { EXTRA_UI_COLUMNS } from "../../tables/constants"
import { selectSelectedRowIndex } from "../../detailsPanel/projectTree/projectTreeSlice"

const INITIAL_BOX_STYLES = {
  left: -1,
  top: -1,
  width: -1,
  height: -1,
  borderWidth: -1,
  color: "",
  numWidth: 0,
}

export default function SelectionWidget({ tableId }) {
  const { columns, rows, rowProps } = useSelector((state) => selectTableData(state, tableId))
  const cellsCountInRow = columns.length + EXTRA_UI_COLUMNS
  const { highlightData } = useSelector(selectSelectionData)
  const hiddenRowCount = useSelector(selectSelectedRowIndex)

  const hiddenCellsCount = (hiddenRowCount === -1 ? 0 : hiddenRowCount) * cellsCountInRow

  const theme = useTheme()

  const [boxStyles, setBoxStyles] = useState(INITIAL_BOX_STYLES)

  const highlightRows = useCallback(() => {
    let firstVisibleRowIndex = highlightData.firstRowIndex
    let lastVisibleRowIndex =
      getLastVisibleChildIndex(highlightData.lastRowIndex, rows, rowProps) || highlightData.lastRowIndex
    let firstElement
    let lastElement
    let firstElementIndex
    let lastElementIndex
    switch (highlightData.context) {
      default: {
        //SELECTION_CONTEXT.table
        firstElementIndex = firstVisibleRowIndex * cellsCountInRow + cellsCountInRow - hiddenCellsCount
        lastElementIndex = lastVisibleRowIndex * cellsCountInRow + cellsCountInRow - hiddenCellsCount
        firstElement = document.getElementById(highlightData.contextElementId).children[firstElementIndex]
        lastElement = document.getElementById(highlightData.contextElementId).children[lastElementIndex]
        break
      }
    }

    const contextElementRect = document.getElementById(highlightData.contextElementId).getBoundingClientRect()
    const firstRect = firstElement.getBoundingClientRect()
    const lastRect = lastElement.getBoundingClientRect()

    const top = Math.min(firstRect.top, lastRect.top)
    const bottom = Math.max(firstRect.bottom, lastRect.bottom)
    const height = bottom - top

    setBoxStyles({
      top: top - contextElementRect.top - 1,
      height: height + 1,
      left: 0,
      width: calculateRowWidth(columns) + firstRect.width + 1,
      borderWidth: 2,
      color: theme.colors.select,
      numWidth: firstRect.width,
    })
  }, [
    highlightData.firstRowIndex,
    highlightData.lastRowIndex,
    highlightData.context,
    highlightData.contextElementId,
    rows,
    rowProps,
    columns,
    theme.colors.select,
    cellsCountInRow,
  ])

  const highlightCell = useCallback(() => {
    let cellElement
    switch (highlightData.context) {
      default: {
        //SELECTION_CONTEXT.table
        let cellElementIndex =
          (highlightData.rowIndex + 1) * cellsCountInRow +
          highlightData.columnIndex +
          EXTRA_UI_COLUMNS -
          hiddenCellsCount
        cellElement = document.getElementById(highlightData.contextElementId).children[cellElementIndex]
        break
      }
    }
    const { left, top, width, height } = cellElement.getBoundingClientRect()
    const contextElementRect = document.getElementById(highlightData.contextElementId).getBoundingClientRect()
    setBoxStyles({
      top: top - contextElementRect.top - 1,
      height: height + 1,
      left: left - contextElementRect.left,
      width: width,
      borderWidth: 2,
      color: theme.colors.select,
    })
  }, [highlightData, cellsCountInRow, theme.colors.select])

  useEffect(
    function highlight() {
      if (!highlightData.context) {
        setBoxStyles(INITIAL_BOX_STYLES)
      } else if (highlightData.columnIndex != null) {
        highlightCell()
      } else {
        highlightRows()
      }
    },
    [highlightData, highlightCell, highlightRows]
  )

  return (
    <Styled.Container>
      <Styled.Box styles={boxStyles}>{Boolean(boxStyles.numWidth) && <div></div>}</Styled.Box>
    </Styled.Container>
  )
}
