export const EXTRA_UI_COLUMNS = 1

export const RENDERED_ROWS_COUNT = 30

export const CELL_TEXT_ALIGN = {
  START: "start",
  CENTER: "center",
  END: "end",
}

export const HEADER_ROW_WIDTH = "32px"

export const NESTING_LEVEL_MULTIPLIER = 12

export const ADD_ROW_OPTION = {
  CHILD: "child",
  SIBLING_BELOW: "sibling_below",
  SIBLING_ABOVE: "sibling_above",
}

export const FORMULAS = {
  POSITION_NUMBER: "POSITION_NUMBER",
  QUANTITY_ABSOLUT: "QUANTITY_ABSOLUT",
  CATEGORIES_TOTAL_COST: "CATEGORIES_TOTAL_COST",
  QUANTITY: "QUANTITY",
  UNIT_COST: "UNIT_COST",
  TOTAL_COST: "TOTAL_COST",
}

export const COLUMN_IDS = {
  ID: "id",
  PARENT_INDEX: "parentIndex",
  POSITION: "position",
  CODE: "code",
  ITEM_NAME: "itemName",
  CATEGORY: "category",
  UNIT: "unit",
  QUANTITY: "quantity",
  QUANTITY_ABSOLUT: "quantityAbsolut",
  CATEGORY_MATERIAL_UNIT: "category_material_unit",
  CATEGORY_MATERIAL_TOTAL: "category_material_total",
  CATEGORY_LABOR_UNIT: "category_labor_unit",
  CATEGORY_LABOR_TOTAL: "category_labor_total",
  CATEGORY_EQUIPMENT_UNIT: "category_equipment_unit",
  CATEGORY_EQUIPMENT_TOTAL: "category_equipment_total",
  UNIT_COST: "unitCost",
  TOTAL_COST: "totalCost",
}

export const COLUMN_INDEXES = {
  ID: 0,
  PARENT_INDEX: 1,
  POSITION: 2,
  CODE: 3,
  ITEM_NAME: 4,
  CATEGORY: 5,
  UNIT: 6,
  QUANTITY: 7,
  QUANTITY_ABSOLUT: 8,
}

export const COLUMN_STATUSES = {
  INITIAL: "INITIAL",
  DYNAMIC: "DYNAMIC",
  CUSTOM: "CUSTOM",
}

export const DYNAMIC_COLUMNS_OFFSET = 7

export const TABLE_TYPE = {
  ESTIMATES: "ESTIMATES",
  PIVOT: "PIVOT",
}
