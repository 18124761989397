import React, { memo } from "react"
import * as Styled from "./styled"

function MenuButton({ label, onClick, children, type, firstChild }) {
  return (
    <Styled.Wrapper type={type} label={label} onClick={onClick} firstChild={firstChild}>
      {children}
    </Styled.Wrapper>
  )
}

export default memo(MenuButton)
