import React from "react"
import * as Styled from "./styled"

export default function Checkbox({ title, isChecked, onCheck, disabled }) {
  return (
    <Styled.Wrapper>
      <input id={title} type={"checkbox"} checked={isChecked} onChange={onCheck} disabled={disabled} />
      <label htmlFor={title}>{title}</label>
    </Styled.Wrapper>
  )
}
