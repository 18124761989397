import store from "../../app/store"

export function getActiveContentId(panels) {
  if (!panels) {
    panels = store.getState().panels
  }

  let { tabs, activePanel, activeTabs } = panels
  let activeTab = activeTabs[activePanel]

  if (activeTab == null) return null

  return tabs[activePanel][activeTab].contentId
}
