import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  padding: 5px;

  ul {
    list-style: none;
    padding-left: 0;
  }

  input {
    margin-top: 5px;
    display: block;
    width: 100%;
    padding: 8px 16px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    -webkit-appearance: none;
    border: 1px solid silver;
    transition: border 0.3s ease;
    &:focus {
      outline: none;
    }
  }

  select {
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;
    border: 1px solid silver;
    border-radius: 6px;
    color: #000;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    transition: border 0.3s ease;
  }
`
