import React, { useState, useEffect } from "react"
import * as Styled from "./styled"

export default function Select({ categories, originalValue, onSubmit }) {
  const [value, setValue] = useState(originalValue)

  function onChangeHandler(event) {
    const value = event.target.value
    setValue(value)
  }

  function submit() {
    onSubmit(value)
  }

  useEffect(() => {
    submit()
  }, [value])

  return (
    <Styled.Select onChange={onChangeHandler} name="select">
      <option></option>
      {categories.map((category) => (
        <option selected={originalValue.toLowerCase() === category.toLowerCase()}>{category}</option>
      ))}
    </Styled.Select>
  )
}
