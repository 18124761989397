import React, { useState, useEffect, useRef } from "react"
import * as Styled from "./styled"
import { useDispatch, useSelector } from "react-redux"
import { changeColumnVisibility, selectTableData, visibleAllColumns } from "../tables/tablesSlice"
import { ReactComponent as CheckAll } from "../../icons/checkAll.svg"

export default function ColumnsPopup({ children, tableId }) {
  const tableData = useSelector((state) => selectTableData(state, tableId))
  const { columns } = tableData
  const dispatch = useDispatch()
  const [showPopup, setShowPopup] = useState(false)
  const popupRef = useRef(null)

  function openPopup(e) {
    setShowPopup(!showPopup)
    e.stopPropagation()
  }

  function checkColumn(event, index) {
    dispatch(changeColumnVisibility({ index, tableId }))
    event.stopPropagation()
  }

  function selectAll(e) {
    dispatch(visibleAllColumns({ tableId }))
    e.stopPropagation()
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false)
      }
    }

    if (showPopup) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [showPopup])

  return (
    <Styled.Wrapper onClick={openPopup} ref={popupRef}>
      {children}
      {showPopup && (
        <Styled.Popup>
          {columns.map((column, index) => {
            return (
              <Styled.ListItem onClick={(event) => checkColumn(event, index)} key={column.title}>
                <Styled.Checkbox selected={column.show}>{column.show ? <CheckAll /> : <></>}</Styled.Checkbox>
                {column.title}
              </Styled.ListItem>
            )
          })}
          <div onClick={selectAll}>Select All</div>
        </Styled.Popup>
      )}
    </Styled.Wrapper>
  )
}
