import React from "react"
import * as Styled from "./styled"

export default function Loader() {
  return (
    <Styled.Wrapper>
      <div></div>
      <Styled.Loader />
    </Styled.Wrapper>
  )
}
