import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;

  .editing {
    input {
      margin-right: 10px;
      padding: 3px 6px;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 4px;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      :focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 10%);
      }
    }

    button {
      cursor: pointer;
      outline: 0;
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 3px 24px;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      color: #0d6efd;
      border-color: #0d6efd;
      :hover {
        color: #fff;
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }
`

export const Select = styled.div`
  width: 300px;
  padding: 0 5px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid gray;
  border-radius: 3px;
  span {
    padding: 3px;
    cursor: pointer;
  }
`

export const Current = styled.div``

export const Options = styled.div`
  position: absolute;
  top: 45px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 300px;
  transition: top 0.1s;
  z-index: 50;

  > div {
    border-top: none;
    border: 1px solid gray;
    border-bottom: none;
    cursor: pointer;
  }
  > div:last-child {
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    /* border-radius: 3px; */
    border: 1px solid gray;
    color: #0266ef;
  }
`

export const OptionsItems = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${({ selected }) => (selected ? "#F1F1F1" : "initial")};
  :hover {
    background-color: #f1f1f1;
  }
  > div:first-child {
    flex-grow: 1;
    display: flex;
    padding: 10px 5px;
  }
  > div:last-child {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 10px 5px;
  }
`
