import React, { useState } from "react"
import * as Styled from "./styled"

export default function ClickAndChange({ children, onChange, initialValue }) {
  const [showInput, setShowInput] = useState(false)
  const [value, setValue] = useState(initialValue)

  function clickHandler() {
    setShowInput(true)
  }

  function submitHandler(e) {
    onChange(value)
    setShowInput(false)
    e.stopPropagation()
  }

  return (
    <Styled.Wrapper onClick={clickHandler}>
      {showInput ? (
        <div className="inputContainer">
          <input type="number" value={value} onChange={(e) => setValue(e.target.value)} />
          <button onClick={submitHandler}>OK</button>
        </div>
      ) : (
        children
      )}
    </Styled.Wrapper>
  )
}
