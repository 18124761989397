import styled from "styled-components"

export const Wrapper = styled.div`
  height: ${({ theme }) => theme.height.ribbons};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  width: 100%;
  gap: ${({ theme }) => theme.spacing.s};
  box-sizing: border-box;
`
