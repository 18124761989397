import styled from "styled-components"

export const Container = styled.div`
  width: 80%;
  overflow-x: scroll;
  position: relative;
  background-color: #fff;

  .button {
    background-color: #fff;
    padding: 0;
    padding: 10px;
    display: flex;
    justify-content: right;

    svg {
      position: fixed;
      cursor: pointer;
    }
  }

  .list {
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

export const Header = styled.div`
  height: 60px;
  display: flex;
  align-items: end;
  width: ${({ width }) => width + "px"};
  background-color: white;
  border-bottom: 1px solid silver;
`
