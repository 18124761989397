import styled from "styled-components"

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px;
`

export const SelectContainer = styled.div`
  margin-bottom: 10px;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 10fr repeat(4, 3fr) 1fr;
  border: ${({ theme, selected }) => (selected ? `1px solid ${theme.colors.select}` : "0.5px solid lightgray")};
  &:first-child {
    font-weight: bold;
  }
  > div {
    background-color: ${({ selected, highlighted }) => (selected || highlighted ? "rgba(58,152,186,0.1)" : "initial")};
    box-sizing: border-box;
    border-right: 1px solid lightgray;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    padding: 5px;
    display: flex;
    align-items: center;
    > input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      text-align: center;
      background-color: transparent;
    }
  }
  > div:first-child {
    justify-content: left;
    input {
      text-align: left;
    }
  }
  > div:last-child {
    justify-content: center;
  }
  button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    transition: color 0.3s;
    :hover {
      color: red;
    }
  }
`

export const ExpandButton = styled.button`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 5px 5px 0;
`

export const FilterCell = styled.div`
  text-align: center;
  .link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-style: italic;
    user-select: none;
  }
`

export const TemplateActions = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  margin-bottom: 15px;
  div:first-child {
    display: flex;
    justify-content: space-between;
    grid-column: span 8;
  }
  button {
    background-color: #fff;
    border-radius: 8px;
    color: red;
    border: 1px solid red;
    padding: 5px 10px;
    cursor: pointer;
    transition: color 0.1s;
    transition: background-color 0.1s;
    &:hover {
      background-color: red;
      color: #fff;
    }
  }

  .editing {
    button {
      color: green;
      border: 1px solid green;
      &:hover {
        background-color: green;
        color: #fff;
      }
    }
    input {
      outline: none;
      padding: 4px 8px;
      border: none;
      border-bottom: 1px solid gray;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
  .rename {
    button:first-child {
      color: orange;
      margin-right: 10px;

      border: 1px solid orange;
      &:hover {
        background-color: orange;
        color: #fff;
      }
    }
    button:last-child {
      color: green;
      border: 1px solid green;
      &:hover {
        background-color: green;
        color: #fff;
      }
    }
  }
`

export const Addrow = styled.div`
  button {
    font-weight: bold;
    border-radius: 8px;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: color 0.1s;
    transition: background-color 0.1s;
    background-color: #fff;
  }
`

export const Tools = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  > select {
    padding: 7px 100px 7px 5px;
  }
  > button {
    position: absolute;
    top: 38px;
    left: 1px;
    width: 224px;
    padding: 10px;
    color: blue;
  }
`
