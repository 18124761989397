import { HISTORY_MAX_ENTIRES } from "../constants"

let histories = {
  1: {
    records: [],
    index: -1,
  },
}

window.histories = histories

function push(id, state) {
  let records = histories[id]?.records ?? []
  let index = histories[id]?.index ?? -1

  if (index < records.length - 1) {
    records = records.slice(0, index + 1)
  }
  records.push(state)
  index++

  if (records.length > HISTORY_MAX_ENTIRES) {
    records.shift()
    index--
  }
  histories[id] = { records, index }
}

function prev(id) {
  let records = histories[id]?.records ?? []
  let index = histories[id]?.index ?? -1
  if (index === 0) return
  index--
  histories[id] = { records, index }

  return records[index]
}

function next(id) {
  let records = histories[id]?.records ?? []
  let index = histories[id]?.index ?? -1

  if (index === records.length - 1) return
  index++
  histories[id] = { records, index: index }

  return records[index]
}

function isEmpty(id) {
  let records = histories[id]?.records ?? []

  return records.length === 0
}

export const history = { isEmpty, push, prev, next }
