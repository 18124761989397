import { createSlice } from "@reduxjs/toolkit"

import { nanoid } from "nanoid"

const initialState = {
  activePanel: 0,
  activeTabs: [null, null],
  tabs: [[], []],
}

const panelsSlice = createSlice({
  name: "panels",
  initialState,
  reducers: {
    addTab(state, action) {
      let { panelIndex, contentId, contentType } = action.payload
      if (panelIndex == null) {
        panelIndex = state.activePanel
      }
      state.tabs[panelIndex].push({ contentId, contentType, id: nanoid() })
      state.activeTabs[panelIndex] = state.tabs[panelIndex].length - 1
      state.activePanel = panelIndex
    },
    setActiveTab(state, action) {
      let { panelIndex, tabIndex } = action.payload
      state.activeTabs[panelIndex] = tabIndex
      state.activePanel = panelIndex
    },
    setActivePanel(state, action) {
      let { panelIndex } = action.payload
      state.activePanel = panelIndex
    },
    closeTab(state, action) {
      let { panelIndex, tabIndex } = action.payload
      if (panelIndex === 0 && state.tabs[panelIndex].length === 1) {
        return
      }
      state.tabs[panelIndex].splice(tabIndex, 1)
      if (!state.tabs[panelIndex].length) {
        state.activeTabs[panelIndex] = null

        return
      }
      if (state.activeTabs[panelIndex] === tabIndex) {
        state.activeTabs[panelIndex] = state.tabs[panelIndex].length - 1
      } else {
        if (state.activeTabs[panelIndex] > tabIndex) {
          state.activeTabs[panelIndex]--
        }
      }
    },
  },
})

export function selectPanels(state) {
  return state.panels
}

export const { addTab, setActiveTab, closeTab, setActivePanel } = panelsSlice.actions

export default panelsSlice.reducer
