import React, { useState } from "react"
import { checkIsNumber } from "../../helpers"
import * as Styled from "./styled"

export default function TextEditor({ type, initialValue, onSubmit }) {
  const [value, setValue] = useState(initialValue)

  function onChangeHandler(event) {
    const value = event.target.value
    if (type === "number" && value !== "") {
      if (event.nativeEvent.inputType === "insertText") {
        if (checkIsNumber(value)) {
          setValue(value)
        }
      } else {
        setValue(value)
      }
    } else {
      setValue(value)
    }
  }

  function submit() {
    onSubmit(value)
  }

  function onKeyDown(event) {
    if ((event.code === "Enter" || event.code === "NumpadEnter") && !event.shiftKey) {
      event.preventDefault()
      submit()
    }
  }

  function moveCaretAtTheEnd(event) {
    let temp_value = event.target.value
    event.target.value = ""
    event.target.value = temp_value
  }

  return (
    <Styled.TextEditor
      rows={4}
      autoFocus
      onFocus={moveCaretAtTheEnd}
      value={value === null ? "" : value}
      onKeyDown={onKeyDown}
      onBlur={submit}
      onChange={onChangeHandler}
    />
  )
}
