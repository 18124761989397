import styled from "styled-components"
import cover from "../../../../assets/cover.jpg"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  padding: ${({ theme }) => theme.spacing.s};
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.xs};
  > button {
    padding: 4px 0px;
    width: 80px;
    cursor: pointer;
  }
  > button:first-child {
    background-color: #fff;
    color: black;
    border: 1px solid #ccc;
  }
  > button:last-child {
    background-color: black;
    color: #fff;
    border: 1px solid #ccc;
  }
`

export const Banner = styled.div`
  height: calc(100vh - 42px);
  background-image: url(${cover});
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`
