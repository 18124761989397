import styled from "styled-components"

export const Wrapper = styled.div`
  .inputContainer {
    width: 170px;
    display: flex;

    input {
      width: 140px;
    }
  }
`
