import { useMemo } from "react"
import { useSelector } from "react-redux"
import { findRowsTemplateIndex } from "../features/limitExpenses/helpers"
import { generateNestedRows, getChildren, isParent } from "../features/tables/helpers"
import { selectTableData } from "../features/tables/tablesSlice"
import { COLUMN_IDS } from "../features/tables/constants"
import { getColumnIndexById } from "../features/tables/helpers"

export default function useTemplates({ tableId, rowIndexes = [] }) {
  const { templates, bindings } = useSelector((state) => state.limitExpenses)

  const { rows } = useSelector((state) => selectTableData(state, tableId))

  const uniqueTemplateIndexesByRows = useMemo(() => {
    let res = {}
    rowIndexes.forEach((rowIndex) => {
      let templateIndexes = new Set()
      templateIndexes.add(findRowsTemplateIndex(rows, rowIndex, bindings))
      for (let { index: i } of generateNestedRows(rows, rowIndex)) {
        templateIndexes.add(findRowsTemplateIndex(rows, i, bindings))
      }
      res[rowIndex] = [...templateIndexes]
    })

    return res
  }, [rowIndexes, rows, bindings])

  const firstTotalsByRows = useMemo(() => {
    let res = {}
    for (let index in uniqueTemplateIndexesByRows) {
      let rowIndex = Number(index)
      let uniqueTemplateIndexes = uniqueTemplateIndexesByRows[rowIndex]
      let allFirstTotal = []

      uniqueTemplateIndexes.forEach((templateIndex) => {
        let result = 0
        let resourcesIterator = generateNestedRows(rows, rowIndex, true)
        for (let { index, row } of resourcesIterator) {
          if (templateIndex === findRowsTemplateIndex(rows, index, bindings)) {
            result += row[getColumnIndexById(COLUMN_IDS.TOTAL_COST)].v
          }
        }
        allFirstTotal.push(result)
      })

      res[rowIndex] = allFirstTotal
    }

    return res
  }, [uniqueTemplateIndexesByRows, rows, bindings])

  const calculateTemplateTotals = useMemo(() => {
    let res = []
    for (let rowIndex in uniqueTemplateIndexesByRows) {
      const rowTemplates = {
        rowIndex: rowIndex,
        templates: [],
      }
      let uniqueTemplateIndexes = uniqueTemplateIndexesByRows[rowIndex]
      uniqueTemplateIndexes.forEach((templateIndex, i) => {
        const local = []
        let template = templates[templateIndex]
        let templateData = {
          name: template.name,
          index: templateIndex,
          rows: [],
        }

        template.rows.forEach((row, index) => {
          if (index === 0) {
            templateData.rows.push({
              description: row.description,
              percentage: row.percentage,
              fixedValue: row.fixedValue,
              amount: 0,
              total: firstTotalsByRows[rowIndex][i],
              filter: [...row.filter],
            })
          } else {
            let amount = 0
            if (row.from.length) {
              row.from.forEach((index) => {
                amount += templateData.rows[index].total
              })
            } else {
              if (isParent(Number(rowIndex))) {
                let childIndex = Number(rowIndex) + 1
                while (
                  rows[childIndex] &&
                  rows[childIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX)].v >= Number(rowIndex)
                ) {
                  let childsTemplateIndex = bindings[rows[childIndex][getColumnIndexById(COLUMN_IDS.ID)].v]
                  if (isParent(childIndex)) {
                    if (childsTemplateIndex !== templateIndex) {
                      childIndex += getChildren(rows, childIndex).length
                    }
                  } else {
                    if (!childsTemplateIndex || childsTemplateIndex === templateIndex) {
                      if (row.filter.includes(rows[childIndex][getColumnIndexById(COLUMN_IDS.CATEGORY)].v)) {
                        amount += rows[childIndex][getColumnIndexById(COLUMN_IDS.TOTAL_COST)].v
                      }
                    }
                  }
                  childIndex++
                }
              }
            }

            amount *= row.percentage / 100
            amount += row.fixedValue
            let total = amount + templateData.rows[index - 1].total
            templateData.rows.push({
              description: row.description,
              percentage: row.percentage,
              fixedValue: row.fixedValue,
              amount: amount,
              total: total,
              filter: [...row.filter],
            })
            local.push(templateData)
          }
        })
        rowTemplates.templates.push(templateData)
      })

      res.push(rowTemplates)
    }

    return res
  }, [uniqueTemplateIndexesByRows, templates, firstTotalsByRows, rows, bindings])

  return {
    uniqueTemplateIndexesByRows,
    templates,
    calculateTemplateTotals,
  }
}
