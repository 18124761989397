import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
`
export const List = styled.ul`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 5px;
  margin: 0;
  position: absolute;
  background-color: gray;
  z-index: 50;
  left: 0px;
  top: 15px;
  border-radius: 5px;
`
