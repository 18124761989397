import styled from "styled-components"

export const Backdrop = styled.div`
  background-color: rgba(96, 116, 113, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 60;
`
