import React from "react"
import * as Styled from "./styled"

export default function Cell({ children, isHeader, width }) {
  return (
    <Styled.Cell isHeader={isHeader} width={width}>
      {children}
    </Styled.Cell>
  )
}
