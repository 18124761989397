import styled from "styled-components"

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const TooltipContent = styled.div`
  position: absolute;
  background-color: #86b7fe;
  padding: 10px;
  color: #fff;
  z-index: 500;
  border-radius: 4px;
  font-size: 12px;
  bottom: -40;
  left: -50;
  ${({ position }) => position === "left" && { left: -110, bottom: -5 }};
  ${({ position }) => (position === "bottom" || !position) && { bottom: -40 }};
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;
`
