import React, { useCallback, useEffect, useState, useRef } from "react"
import { CONTEXT_MENU_CONSTANTS } from "../../app/constants"
import * as Styled from "./styled"

const CONTEXT_MENU_ITEMS = {
  [CONTEXT_MENU_CONSTANTS.ROW]: {
    items: [
      { id: 1, text: "Add Child Row" },
      { id: 2, text: "Add Row Above" },
      { id: 3, text: "Add Row Below" },
      { id: 4, text: "Remove Row" },
      { id: 6, text: "Insert Copied Above" },
      { id: 5, text: "Insert Copied Below" },
    ],
  },
  [CONTEXT_MENU_CONSTANTS.TABS]: {
    items: [{ id: 1, text: "Open in new panel" }],
  },
}

export const ContextMenuContext = React.createContext()

export function ContextMenuContextProvider({ children }) {
  const [show, setShow] = useState(false)
  const [name, setName] = useState()
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [handlers, setHandlers] = useState()
  const menuRef = useRef(null) // Reference to context menu

  const showContextMenu = useCallback((name, event, handlers, options = {}) => {
    event.preventDefault()
    let nextPosition = {
      x: options.x || event.pageX,
      y: options.y || event.pageY,
    }

    let height = CONTEXT_MENU_ITEMS[name].items.length * 40
    let width = 200

    if (window.innerHeight - event.clientY < height) {
      nextPosition.y -= height
    }

    if (window.innerWidth - event.clientX < width) {
      nextPosition.x -= width
    }

    setPosition(nextPosition)
    setShow(true)
    setName(name)
    setHandlers(handlers)
  }, [])

  // Close menu when clicking outside
  useEffect(() => {
    function closeMenuContext(event) {
      // If click is outside the menu, close it
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShow(false)
      }
    }

    if (show) {
      document.addEventListener("mousedown", closeMenuContext)
    }

    return () => {
      document.removeEventListener("mousedown", closeMenuContext)
    }
  }, [show])

  return (
    <ContextMenuContext.Provider value={{ showContextMenu }}>
      {show && (
        <Styled.MenuContextWrapper
          ref={menuRef} // Attach the ref to the menu
          onContextMenu={(e) => e.preventDefault()}
          onClick={() => setShow(false)}
          x={position.x}
          y={position.y}
        >
          {CONTEXT_MENU_ITEMS[name]?.items.map((item, index) => (
            <div key={item.id} onClick={handlers[index]}>
              {item.text}
            </div>
          ))}
        </Styled.MenuContextWrapper>
      )}
      {children}
    </ContextMenuContext.Provider>
  )
}
