import axios from "axios"

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: { "content-type": "application/json" },
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken")
  if (token) {
    config.headers.Authorization = `JWT ${token}`
  }

  return config
})

export async function createNewProject(data) {
  return api.post("projects/create", data).then((res) => {
    if (res.data.status !== "success") {
      throw new Error("something went wrong")
    }

    return res.data.projectId
  })
}

export async function fetchAllProjects() {
  return api.get("projects").then((res) => {
    if (res.data.status !== "success") {
      throw new Error("something went wrong")
    }

    return res.data.projects
  })
}

export async function fetchAllProjectsMeta() {
  return api.get("projects-meta").then((res) => {
    if (res.data.status !== "success") {
      throw new Error("something went wrong")
    }

    return res.data.projects
  })
}

export async function fetchProject(id) {
  return api.get("projects/" + id).then((res) => {
    if (res.data.status !== "success") {
      throw new Error("something went wrong")
    }

    return res.data.project
  })
}

export async function getDataFromExcel(file) {
  const formData = new FormData()
  formData.append("file", file)

  const data = await api.post("upload", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })

  return data.data.rows
}

export async function getExcelFromData(data) {
  return api
    .post(
      "projects/export",
      { data },
      {
        responseType: "blob",
      }
    )
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      // Create a link element to download the file
      const a = document.createElement("a")
      a.href = url
      a.download = "data.xlsx" // File name for download
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
}

export async function saveEstimates(projectId, data) {
  return api.post("projects/save-estimates", { projectId, data }).then((res) => {
    if (res.data.status !== "success") {
      throw new Error("can't save")
    }

    return
  })
}

export function register({ name, email, password, country, phone }) {
  return api.post("auth/register", { name, email, password, country, phone }).then((res) => {
    localStorage.setItem("accessToken", res.data.accessToken)
  })
}

export function login({ email, password }) {
  return api.post("auth/login", { email, password }).then((res) => {
    localStorage.setItem("accessToken", res.data.accessToken)

    return res
  })
}

export function authCheck(token) {
  return api.get("auth-check", {
    headers: {
      Authorization: "JWT " + token,
    },
  })
}
