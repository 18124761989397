import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 28px 60px;
  width: 100vw;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xxxxxl};

  button {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    outline: 0;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    border: 1px solid transparent;
    background-color: #fff;
    padding: 3px 24px;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
    color: #0d6efd;
    border-color: #0d6efd;
    :hover {
      color: #fff;
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }

  .popup {
    input {
      margin-right: 10px;
      padding: 3px 6px;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 4px;
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      :focus {
        color: #212529;
        background-color: #fff;
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 10%);
      }
    }

    button {
      position: initial;
      cursor: pointer;
      outline: 0;
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      background-color: transparent;
      border: 1px solid transparent;
      padding: 3px 24px;
      font-size: 1rem;
      border-radius: 0.25rem;
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      color: #0d6efd;
      border-color: #0d6efd;
      :hover {
        color: #fff;
        background-color: #0d6efd;
        border-color: #0d6efd;
      }
    }
  }
`

export const ProjectsWrapper = styled.div`
  height: 100%;
  width: 50vw;
  max-width: 600px;
  padding: ${({ theme }) => theme.spacing.l};
  border: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  border-radius: ${({ theme }) => theme.radius.s};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    all: unset;
    font-size: 18px;
    font-weight: 600;
    display: block;
  }

  span {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSize.s};
    color: #989ca0;
    margin-top: ${({ theme }) => theme.spacing.xs};
  }

  button {
    all: unset;
    color: ${({ theme }) => theme.colors.activeText};
    font-size: ${({ theme }) => theme.fontSize.s};
    cursor: pointer;

    &:hover {
      scale: 1.05;
    }
  }
`

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
`

export const ListHeader = styled.li`
  margin-top: ${({ theme }) => theme.spacing.xxl};
  padding: ${({ theme }) => theme.spacing.s};
  background-color: ${({ theme }) => theme.colors.rowSelect};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  border-top-right-radius: ${({ theme }) => theme.radius.s};
  border-top-left-radius: ${({ theme }) => theme.radius.s};
  display: flex;

  div:first-child {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: ${({ theme }) => theme.spacing.s};
  }

  & > div:nth-child(2) {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: ${({ theme }) => theme.spacing.s};
    border-left: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  }

  h4 {
    all: unset;
    font-size: ${({ theme }) => theme.fontSize.s};
    font-weight: 600;
    width: 60%;
  }
`

export const ListItem = styled.li`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  font-size: ${({ theme }) => theme.fontSize.s};
  padding: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
`
