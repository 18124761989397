import React, { useCallback, memo, useState, useMemo } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import * as Styled from "./styled"
import { useDispatch } from "react-redux"
import { resizeColumn, updateCell } from "../tablesSlice"
import { config } from "../../../app/config.js"
import { roundTo } from "../helpers"
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextEditor from "./TextEditor"
import Select from "./Select"

function Cell({
  cellProps,
  expanded,
  isHeader,
  isFirstColumn,
  nestingLevel,
  column,
  columnIndex,
  rowIndex,
  edited,
  align,
  tableId,
  showArrow,
  toggleExpanded,
  onSelect,
  selected,
  isCategory,
  isParent,
  categories,
  categoriesUnitCosts,
}) {
  const dispatch = useDispatch()
  const currentClientWidthRef = useRef(0)
  const isMouseDownRef = useRef(false)
  const lineRef = useRef()
  const wrapperRef = useRef()
  const initialCellWidthRef = useRef(column.width)
  const [editing, setEditing] = useState(false)

  function startEditing() {
    if (!column.editable || isFirstColumn) {
      return
    }
    setEditing(true)
  }

  function updateValue(value) {
    dispatch(updateCell({ rowIndex, columnIndex, value }))
    setEditing(false)
  }

  const onMouseDownHandler = useCallback((e) => {
    currentClientWidthRef.current = e.clientX
    isMouseDownRef.current = true
  }, [])

  const onMouseMovehandler = useCallback(
    (e) => {
      if (!isMouseDownRef.current) {
        return
      }
      const change = e.clientX - currentClientWidthRef.current

      if (
        initialCellWidthRef.current + change > config.table.minColWidth &&
        window.innerWidth > e.clientX &&
        initialCellWidthRef.current + change < config.table.maxColWidth
      ) {
        dispatch(resizeColumn({ index: columnIndex, size: initialCellWidthRef.current + change, tableId }))
      }
    },
    [columnIndex, dispatch, tableId]
  )

  const onMouseUpHandler = useCallback(
    (e) => {
      initialCellWidthRef.current = column.width
      isMouseDownRef.current = false
    },
    [column.width]
  )

  useEffect(() => {
    if (isHeader) {
      lineRef.current.addEventListener("mousedown", onMouseDownHandler)
      window.addEventListener("mouseup", onMouseUpHandler)
      window.addEventListener("mousemove", onMouseMovehandler)
    }
  }, [onMouseDownHandler, onMouseMovehandler, onMouseUpHandler, isHeader])

  function renderValue(value) {
    return typeof value === "number" ? roundTo(value, 2) : value === "" || value == null ? "-" : value
  }

  const levelLine = useMemo(() => {
    const lines = []
    for (let i = 0; i <= nestingLevel; i++) {
      if (i !== 0) {
        lines.push(<div></div>)
      }
      if (i === nestingLevel && column.expandable && showArrow) {
        lines.push(
          <FontAwesomeIcon
            isLine={false}
            icon={expanded ? faChevronDown : faChevronRight}
            size="xs"
            onClick={toggleExpanded}
            onDoubleClick={toggleExpanded}
          />
        )
      }
    }

    return lines
  }, [column.expandable, expanded, nestingLevel, showArrow, toggleExpanded])

  return (
    <Styled.Wrapper
      width={column.width}
      align={align}
      isFirstColumn={isFirstColumn}
      isHeader={isHeader}
      ref={wrapperRef}
      onDoubleClick={startEditing}
      edited={edited && column.formula}
      editing={editing}
      onClick={onSelect}
      selected={selected && !isCategory}
    >
      <Styled.Content
        isLocked={false}
        editable={column.editable}
        isHeader={isHeader}
        nestingLevel={nestingLevel}
        showArrow={showArrow}
      >
        {levelLine}
        {!isCategory && <span>{renderValue(cellProps.v)}</span>}
      </Styled.Content>

      {editing && !isCategory && !(isParent && categoriesUnitCosts.includes(columnIndex)) && (
        <TextEditor type={column.type} initialValue={cellProps.v} onSubmit={updateValue} />
      )}
      {isCategory && <Select originalValue={cellProps.v} categories={categories} onSubmit={updateValue} />}
    </Styled.Wrapper>
  )
}

export default memo(Cell)
