import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  height: ${({ theme, panelsCount }) => `calc(${theme.height.full} / ${panelsCount})`};
  overflow: hidden;
  border-radius: 20px 0 0 0;
  box-shadow: 0px -9px 24px 0px rgba(0, 0, 0, 0.06);
`

export const TabsWrapper = styled.div`
  position: sticky;
  background-color: #fff;
  z-index: ${({ theme }) => theme.zIndexes.z40};
  top: 0;
  left: 0;
  padding: ${({ theme }) => `${theme.spacing.l} ${theme.spacing.l} 0`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  display: flex;
  align-items: flex-end;
  height: ${({ theme }) => theme.height.tabs};
  box-sizing: border-box;

  svg {
    align-self: center;
    width: 18px;
  }

  ul {
    flex-grow: 1;
    display: flex;
    list-style-type: none;
    gap: ${({ theme }) => `0 ${theme.spacing.xs}`};
    margin: ${({ theme }) => `0 ${theme.spacing.m}`};
    padding: 0;

    svg {
      margin-left: 4px;
    }

    li {
      width: 200px;
    }
  }
`

export const Tab = styled.li`
  display: flex;
  ${({ active }) => active && "position: relative; border-bottom: 1px solid #fff; top: 1px;"}
  justify-content: space-between;
  align-items: center;
  border-radius: 4px 4px 0px 0px;
  color: ${({ theme }) => theme.colors.textPrimary};
  background-color: ${({ theme, active }) => (!active ? theme.colors.rowSelect : theme.colors.active)};
  border: ${({ theme }) => `1px solid ${theme.colors.stroke}`};
  border-bottom: none;
  padding: 0.5rem;
  width: 200px;
  cursor: pointer;

  svg {
    width: 10px;

    path {
      fill: ${({ theme, active }) => (active ? theme.colors.activeText : theme.colors.inactiveText)};
    }
  }
`

export const Main = styled.div`
  width: ${({ theme, isExtended }) => (!isExtended ? "100%" : `calc(100% - ${theme.width.detailsWidth})`)};
`

export const DropdownContainer = styled.div`
  position: relative;
`

export const DropdownButton = styled.button`
  padding: 8px 12px;
  background: ${({ theme }) => theme.colors.active};
  color: ${({ theme }) => theme.colors.activeText};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export const DropdownMenu = styled.div`
  position: absolute;
  right: 0;
  margin-top: 5px;
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 200px;
`

export const DropdownItem = styled.div`
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.rowSelect};
  }
`
