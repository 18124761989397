import { copyHandler, cutHandler, pasteHandler, redoHandler, undoHandler } from "./actions"

export function addDocumentListeners() {
  document.addEventListener("keydown", historyHandler)
  document.addEventListener("keydown", copyPasteCutHandler)
}

export function removeDocumentListeners() {
  document.removeEventListener("keydown", historyHandler)
  document.removeEventListener("keydown", copyPasteCutHandler)
}

function historyHandler(event) {
  if (["input", "textarea"].includes(event.target.type)) {
    return
  }
  if ((event.ctrlKey || event.metaKey) && !event.shiftKey && event.key === "z") {
    undoHandler()
  }
  if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key.toLowerCase() === "z") {
    redoHandler()
  }
}

function copyPasteCutHandler(event) {
  if (event.repeat) return

  if (event.ctrlKey || event.metaKey) {
    if (event.key.toLowerCase() === "c") {
      copyHandler()
    }

    if (event.key.toLowerCase() === "v") {
      pasteHandler()

      return
    }
    if (event.key.toLowerCase() === "x") {
      cutHandler()

      return
    }

    return
  }
}
