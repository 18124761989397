import { useDispatch } from "react-redux"
import FirstCell from "../FirstCell"
import * as Styled from "./styled"
import { selectRow, unselectRow } from "../tablesSlice"
import { HEADER_ROW_WIDTH } from "../constants"
import ColumnResize from "./ColumnResize"
import { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons"

export default function HeaderRow({ tableId, columns, isFirstColumn, selected, width }) {
  const [resizeColumnIndex, setResizeColumnIndex] = useState(null)

  const dispatch = useDispatch()

  function toggleSelection() {
    switch (selected) {
      case 0:
        dispatch(selectRow({ rowIndex: -1, withChildren: true }))
        break
      case 1:
        dispatch(unselectRow({ rowIndex: -1 }))
        break

      default:
        break
    }
  }

  function hideColumnResize() {
    setResizeColumnIndex(null)
  }

  return (
    <Styled.Row width={width} height={HEADER_ROW_WIDTH}>
      <FirstCell rowIndex={-1} selected={selected} onSelect={toggleSelection} />
      {columns.map(
        (column, i) =>
          column.show && (
            <Styled.Cell width={column.width} isFirstColumn={isFirstColumn} align={column.align} key={column.title}>
              <Styled.Title>
                <div>{column.title}</div>
                <>
                  {resizeColumnIndex === i ? (
                    <ColumnResize cellIndex={i} tableId={tableId} onClose={hideColumnResize} />
                  ) : (
                    <Styled.Icon onClick={() => setResizeColumnIndex(i)}>
                      <FontAwesomeIcon icon={faEllipsisVertical} />
                    </Styled.Icon>
                  )}
                </>
              </Styled.Title>
            </Styled.Cell>
          )
      )}
    </Styled.Row>
  )
}
