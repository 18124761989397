import React from "react"
import * as Styled from "./styled"

export default function Popup({ children, onClose }) {
  function clickHandler(event) {
    if (event.target === event.currentTarget) {
      onClose()
    }
  }

  return <Styled.Backdrop onClick={clickHandler}>{children}</Styled.Backdrop>
}
