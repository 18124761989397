import styled from "styled-components"

export const Wrapper = styled.div`
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  background-color: #fff;

  margin-left: ${({ type, firstChild }) => (type && firstChild ? "auto" : "initial")};

  svg {
    width: ${({ type }) => (type ? "90px" : "28px")};
    vertical-align: middle;
    color: ${({ theme }) => theme.colors.textPrimary};
    cursor: pointer;
    :hover {
      scale: 1.1;
    }
  }

  span {
    vertical-align: middle;
    margin-left: 0.5rem;
    font-size: 1.2rem;
    font-weight: 500;
  }
`
