import styled from "styled-components"

export const MenuContextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndexes.z50};
  top: ${({ y }) => `${y}px`};
  left: ${({ x }) => `${x}px`};
  width: 200px;
  background-color: #414a4c;
  gap: 2px;
  user-select: none;

  > div {
    background-color: #353839;
    color: white;
    padding: 1rem;
    cursor: pointer;
  }

  > div:hover {
    background-color: #232b2b;
  }
`
