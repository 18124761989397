import { ThemeProvider } from "styled-components"

const primary = {
  colors: {
    primary: "#F5F5F5",
    secondary: "#111213",
    header: "#F2F3F3",
    textPrimary: "#141719",
    textSecondary: "#FFFFFF",
    textBlur: "#636669",
    menuItemSelect: "#202122",
    rowSelect: "#FAFAFA",
    active: "#FFFFFF",
    activeText: "#0266EF",
    inactiveText: "#646464",
    stroke: "#EBEBEB",
    select: "#0266EF",
    rowHover: "#F1F1F1",
    detailsBackground: "#F9F8F8",
  },
  zIndexes: {
    z10: 10,
    z20: 20,
    z30: 30,
    z40: 40,
    z50: 50,
  },
  spacing: {
    xs: "4px",
    s: "8px",
    m: "12px",
    l: "16px",
    xl: "20px",
    xxl: "24px",
    xxxl: "28px",
    xxxxl: "32px",
    xxxxxl: "40px",
  },
  fontSize: {
    s: "12px",
    m: "14px",
    extraLarge: "24px",
  },
  radius: {
    xs: "4px",
    s: "8px",
    m: "12px",
    l: "16px",
    xl: "20px",
    xxl: "24px",
    rounded: "50%",
  },
  height: {
    tabs: "43px",
    ribbons: "48px",
    full: "100vh",
    half: "50vh",
    column: "35px",
    expandedExpenses: "250px",
  },
  width: {
    full: "240px",
    extended: "44px",
    detailsWidth: "350px",
  },
}

export default function ThemeContextProvider({ children }) {
  return <ThemeProvider theme={primary}>{children}</ThemeProvider>
}
