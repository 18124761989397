import * as Styled from "./styled"
import { useEffect } from "react"
import Button from "../../../button"
import { useDispatch } from "react-redux"
import { setPage } from "../../pagesSlice"
import { PAGES } from "../../constants"
import { authCheck } from "../../../../app/api"

export default function InitialPage() {
  const dispatch = useDispatch()

  function navigateToLogin() {
    dispatch(setPage({ page: PAGES.LOGIN }))
  }

  function navigateToRegister() {
    dispatch(setPage({ page: PAGES.REGISTER }))
  }

  function naviageToDashboard() {
    dispatch(setPage({ page: PAGES.DASHBOARD }))
  }

  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      authCheck(token).then((res) => {
        if (res.data.message === "Authenticated") {
          naviageToDashboard()
        }
      })
    }
  }, [])

  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Button title="Log In" onClick={navigateToLogin} />
        <Button title="Sign Up" onClick={navigateToRegister} />
      </Styled.Header>
      <Styled.Banner />
    </Styled.Wrapper>
  )
}
