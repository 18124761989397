import React from "react"
import * as Styled from "./styled"
import { ReactComponent as FolderIcon } from "../../icons/folder.svg"
import { ReactComponent as EstimateIcon } from "../../icons/note.svg"
import { ReactComponent as ArrowIcon } from "../../icons/arrow-left.svg"
import { Item } from "./menuItem/styled"
import { useState } from "react"
import { selectActiveProject, selectAllProjects } from "../projects/projectsSlice"
import { createTable } from "../tables/tablesSlice"
import { useSelector, useDispatch } from "react-redux"
import { changeLoaderVisibility } from "../loader/loaderSlice"
import { addTab, selectPanels } from "../panels/panelSlice"
import { CONTENT_TYPE } from "../panels/constants"
import * as API from "../../app/api"

export default function SideBarMenu() {
  const [isExtended, setIsExtended] = useState(false)
  const activeProject = useSelector(selectActiveProject)
  const projects = useSelector(selectAllProjects)
  const panels = useSelector(selectPanels)
  const dispatch = useDispatch()

  function extendHandler() {
    setIsExtended(!isExtended)
  }

  function closeTable() {
    dispatch(changeLoaderVisibility({ show: true }))
    localStorage.removeItem("table_id")
    window.location.reload()
  }

  function logOut() {
    dispatch(changeLoaderVisibility({ show: true }))
    localStorage.removeItem("accessToken")
    localStorage.removeItem("table_id")
    window.location.reload()
  }

  function clickHandler(id) {
    API.fetchProject(id).then((project) => {
      const estimates = project.estimates
      dispatch(createTable({ tableId: project.id, data: estimates }))
      dispatch(
        addTab({
          panelIndex: panels.activePanel,
          contentId: id,
          contentType: CONTENT_TYPE.PROJECT,
        })
      )
    })
  }

  return (
    <Styled.SideBar $isExtended={isExtended}>
      <Styled.Wrapper $isExtended={isExtended}>
        <Styled.Controller $isExtended={isExtended}>
          {/* {!isExtended && <MenuIcon />} */}
          <ArrowIcon onClick={extendHandler} />
        </Styled.Controller>
        <Styled.Item>
          <Styled.ItemName>
            <FolderIcon />
            <span>{activeProject?.meta.name}</span>
          </Styled.ItemName>
          <button onClick={closeTable}>Close</button>
        </Styled.Item>
        <Styled.List>
          <Item>
            <EstimateIcon />
            <span>Estimate</span>
          </Item>
          {/* <Item>
							<EstimateIcon />
							<span>Estimate</span>
						</Item> */}
        </Styled.List>
        <Styled.Controller $isExtended={isExtended}>
          <button onClick={logOut}>Log Out</button>
          <ArrowIcon onClick={extendHandler} />
        </Styled.Controller>
        <>
          <Styled.List>
            <Item>
              <Styled.ItemName>
                <FolderIcon />
                <span>Other projects</span>
              </Styled.ItemName>
            </Item>
            {projects.all.map((project) => (
              <Item onClick={() => clickHandler(project.id)}>
                <EstimateIcon />
                <span>{project.meta.name}</span>
              </Item>
            ))}
          </Styled.List>
        </>
      </Styled.Wrapper>
    </Styled.SideBar>
  )
}
