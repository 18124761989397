import styled from "styled-components"

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
`

export const TitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
    background-color: #fff;
    padding: ${({ theme }) => theme.spacing.s};
  }
`

export const DetailsWrapper = styled.div`
  width: 50%;
`
