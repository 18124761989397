import React, { useState, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import { selectCell, selectTableData } from "../../tables/tablesSlice"
import { COLUMN_INDEXES } from "../../tables/constants"
import * as Styled from "./styled"
import ListItem from "./ListItem"

export default function Search({ tableId, tableRef }) {
  const dispatch = useDispatch()
  const [value, setValue] = useState("")
  const [selectedColumn, setSelectedColumn] = useState("ITEM_NAME")
  const { rows } = useSelector((state) => selectTableData(state, tableId))

  function scrollElement(rowIndex, cellIndex) {
    tableRef.scrollTop = rowIndex * 20
    dispatch(selectCell({ rowIndex, cellIndex, tableId }))
  }

  function setSearchItem(e) {
    setValue(e.target.value)
  }

  function ChangeColumn(e) {
    setSelectedColumn(e.target.value)
  }

  const columns = useMemo(() => {
    const options = []
    for (let deps in COLUMN_INDEXES) {
      if (COLUMN_INDEXES[deps] !== COLUMN_INDEXES.ID && COLUMN_INDEXES[deps] !== COLUMN_INDEXES.PARENT_INDEX) {
        options.push(<option value={deps}>{deps}</option>)
      }

      if (COLUMN_INDEXES[deps] === COLUMN_INDEXES.ITEM_NAME) {
        [options[0], options[options.length - 1]] = [options[options.length - 1], options[0]]
      }
    }

    return options
  }, [])

  const list = useMemo(() => {
    const res = []

    if (value.length > 2) {
      rows.forEach((row, rowIndex) => {
        if (row[COLUMN_INDEXES[selectedColumn]].v !== null && row[COLUMN_INDEXES[selectedColumn]].v.includes(value)) {
          res.push(
            <ListItem
              clickHandler={() => scrollElement(rowIndex, COLUMN_INDEXES[selectedColumn])}
              key={row[COLUMN_INDEXES.ID]}
              properties={{ position: row[COLUMN_INDEXES.POSITION].v, itemName: row[COLUMN_INDEXES[selectedColumn]].v }}
            />
          )
        }
      })
    }

    return res
  }, [rows, value])

  return (
    <Styled.Wrapper>
      <select onChange={ChangeColumn}>{columns}</select>
      <input type="search" onChange={setSearchItem} placeholder="შეიყვანეთ საძიებო სიტყვა" />

      <ul>{list}</ul>
    </Styled.Wrapper>
  )
}
