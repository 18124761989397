import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  border: 1px solid #1f2937;
  z-index: 1;
  top: 35px;
  left: 30px;
  width: 100px;
  padding: 4px;
  border-radius: 4px;
  background: #111827;
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: #fff;
  & div {
    background-color: #0d121d;
    border-radius: 4px;
    padding: 4px;
    transition:
      all 0.3s ease-in-out,
      border-color 0.3s ease-in-out;
    color: rgb(156, 163, 175);
    &:hover {
      color: #fff;
    }
  }
`
