import { configureStore } from "@reduxjs/toolkit"
import historyMiddleware from "./middlewares/historyMiddleware"
import tablesReducer from "../features/tables/tablesSlice"
import panelsReducer from "../features/panels/panelSlice"
import selectionSlice from "../features/selection/selectionSlice"
import limitExpensesReducer from "../features/limitExpenses/limitExpensesSlice"
import loaderSlice from "../features/loader/loaderSlice"
import projectTreeSlice from "../features/detailsPanel/projectTree/projectTreeSlice"
import tableMiddleware from "./middlewares/tableMiddleware"
import baseMiddleware from "./middlewares/baseMiddleware"
import pagesReducer from "../features/pages/pagesSlice"
import columnsMiddleware from "./middlewares/columnsMiddleware"
import projectsSlice from "../features/projects/projectsSlice"

const store = (window.store = configureStore({
  reducer: {
    selection: selectionSlice,
    limitExpenses: limitExpensesReducer,
    loader: loaderSlice,
    projectTree: projectTreeSlice,
    tables: tablesReducer,
    panels: panelsReducer,
    pages: pagesReducer,
    projects: projectsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseMiddleware, historyMiddleware, tableMiddleware, columnsMiddleware),
}))

export default store
