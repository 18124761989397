import { useDispatch, useSelector } from "react-redux"
import Cell from "../Cell"
import { COLUMN_IDS } from "../constants"
import { getAncestorsCount } from "../helpers"
import * as Styled from "./styled"
import React, { useCallback } from "react"
import {
  clearRowSelection,
  selectCell,
  selectCustomCategories,
  selectRow,
  toggleRowExpandedStatus,
  unselectRow,
} from "../tablesSlice"
import FirstCell from "../FirstCell"
import { getColumnIndexById } from "../helpers"

function Row({
  className,
  row,
  columns,
  props,
  rowIndex,
  contextMenuHandler,
  uniqueCategories,
  tableId,
  selected,
  selectedCell,
  isParent,
  filteredRowIndex,
}) {
  const dispatch = useDispatch()
  const categories = useSelector((state) => selectCustomCategories(state, tableId))
  const rowId = row[getColumnIndexById(COLUMN_IDS.ID)].v

  const toggleExpanded = useCallback(() => {
    dispatch(toggleRowExpandedStatus({ id: rowId }))
  }, [dispatch, rowId])

  function getcolumnsUnitCost() {
    let indexes = []
    categories.forEach((category) => {
      indexes.push(getColumnIndexById(COLUMN_IDS[`CATEGORY_${category.toUpperCase()}_UNIT`]))
    })
    indexes.push(getColumnIndexById(COLUMN_IDS.UNIT_COST))

    return indexes
  }

  function toggleSelection(event) {
    switch (selected) {
      case 0:
        dispatch(selectRow({ rowIndex, withChildren: true, combine: event.shiftKey }))
        dispatch(selectCell({ rowIndex: null, cellIndex: null, tableId }))
        break
      case 1:
        if (isParent) {
          dispatch(selectRow({ rowIndex, withChildren: false, combine: event.shiftKey }))
        } else {
          dispatch(unselectRow({ rowIndex }))
        }
        break
      case 2:
        dispatch(unselectRow({ rowIndex }))
        break

      default:
        break
    }
  }

  function selectCellHandler(event, cellIndex) {
    dispatch(selectCell({ rowIndex, cellIndex, tableId }))
    dispatch(clearRowSelection({}))

    event.stopPropagation()
  }

  return (
    <Styled.Row className={className} onContextMenu={(e) => contextMenuHandler(e, rowIndex)} height={props.height}>
      <FirstCell rowIndex={rowIndex} selected={selected} onSelect={toggleSelection} />
      {columns.map(
        (column, columnIndex) =>
          column.show && (
            <Cell
              align={column.align}
              key={column.title + "_" + row[getColumnIndexById(COLUMN_IDS.ID)].v}
              isUnitCost={getColumnIndexById(COLUMN_IDS.U)}
              columnIndex={columnIndex}
              column={column}
              cellProps={row[columnIndex]}
              nestingLevel={column.expandable && getAncestorsCount(row, tableId, filteredRowIndex)}
              expanded={column.expandable && props.expanded}
              showArrow={column.expandable && isParent}
              edited={row[columnIndex].e}
              isParent={isParent}
              uniqueCategories={uniqueCategories}
              tableId={tableId}
              rowIndex={rowIndex}
              row={row}
              toggleExpanded={toggleExpanded}
              onSelect={(event) => selectCellHandler(event, columnIndex)}
              selected={selectedCell.rowIndex === rowIndex && selectedCell.cellIndex === columnIndex}
              isCategory={columnIndex === getColumnIndexById(COLUMN_IDS.CATEGORY)}
              categories={categories}
              categoriesUnitCosts={getcolumnsUnitCost()}
            />
          )
      )}
    </Styled.Row>
  )
}

export default React.memo(Row)
