import { clearHighlight } from "../../features/selection/selectionSlice"
import { setSelectedRowIndex } from "../../features/detailsPanel/projectTree/projectTreeSlice"
import * as API from "../../app/api"

const autoSaveActions = [
  "tables/updateCell",
  "tables/setTable",
  "tables/replaceRow",
  "tables/addRow",
  "tables/removeRow",
  "tables/resizeColumn",
  "tables/toggleRowExpandedStatus",
  "tables/setTableState",
  "tables/updateColumns",
  "tables/setColumns",
  "tables/outdentRows",
  "tables/indentRows",
  "tables/changeColumnVisibility",
  "tables/upRows",
  "tables/downRows",
  "tables/changeColumnWidth",
  "limitExpenses/addTemplateRow",
  "limitExpenses/removeTemplateRow",
  "limitExpenses/removeTemplate",
  "limitExpenses/updateTemplateRow",
  "limitExpenses/assignTemplate",
  "limitExpenses/changeTemlateName",
]

let timeout = null

const tableMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case "tables/removeRow": {
      store.dispatch(clearHighlight())
      if (action.payload.index === store.getState().projectTree.selectedRowIndex) {
        store.dispatch(setSelectedRowIndex(-1))
      }
      break
    }
    case "tables/addRow": {
      if (action.payload.option === "sibling_above") {
        store.dispatch(setSelectedRowIndex(store.getState().projectTree.selectedRowIndex + 1))
      }
      break
    }
    default:
      break
  }

  next(action)

  if (autoSaveActions.includes(action.type)) {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      timeout = null
      let activeProjectId = store.getState().projects.activeProjectId
      let data = store.getState().tables[activeProjectId]
      let expensesData = store.getState().limitExpenses
      API.saveEstimates(activeProjectId, {
        estimates: {
          ...data,
          selectedCell: { cellIndex: null, rowIndex: 0 },
        },
        expenses: {
          ...expensesData,
        },
      })
        .then()
        .catch()
    }, 2000)
  }
}

export default tableMiddleware
