import styled from "styled-components"
import { NESTING_LEVEL_MULTIPLIER } from "../constants"

export const Wrapper = styled.div`
  width: ${({ width, isFirstColumn }) => (isFirstColumn ? "auto" : width + "px")};
  height: calc(100% - 2px);
  display: flex;
  justify-content: ${({ align }) => align};
  background-color: ${({ isHeader, theme, edited }) =>
    isHeader ? theme.colors.header : edited ? theme.colors.editedCell : "inherit"};
  border: ${({ theme, selected }) => (selected ? "1px solid " + theme.colors.select : "1px solid transparent")};
  position: relative;
  transition: width ease-in-out 200ms;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding-right: 15px;
  /* overflow: hidden; */
`

export const Content = styled.div`
  user-select: none;
  display: flex;
  line-height: 20px;
  font-weight: ${({ isHeader }) => (isHeader ? 700 : 400)};
  overflow: hidden;
  /* padding-left: ${({ nestingLevel }) => nestingLevel && nestingLevel * NESTING_LEVEL_MULTIPLIER + "px"}; */

  span {
    cursor: ${({ editable, isHeader }) => (editable && !isHeader ? "pointer" : "default")};
    display: flex;
    width: 100%;
    padding-right: 5px;
  }

  div {
    margin: 0 9px;
    width: 1px;
    height: 100%;
    box-sizing: content-box;
    background-color: silver;
  }

  svg {
    box-sizing: content-box;
    cursor: pointer;
    cursor: pointer;
    fill: ${({ isLocked }) => (isLocked ? "silver" : "initial")};
    padding: 5px;
  }
`

export const Line = styled.div`
  position: absolute;
  width: 5px;
  height: 70%;
  right: 0;
  background-color: #e2e1e1;
  top: 15%;
  cursor: col-resize;
  border: 2px solid ${({ theme }) => theme.colors.header};
  z-index: ${({ theme }) => theme.zIndexes.z40};
`
