import { DETAILS } from "./constants"
import ProjectTree from "./projectTree"
import RowProps from "./rowProps"
import Search from "./search"

export const routes = {
  [DETAILS.PROJECTTREE]: ProjectTree,
  [DETAILS.ROWPROPS]: RowProps,
  [DETAILS.SEARCH]: Search,
}
