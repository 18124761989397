import React from "react"
import * as Styled from "./styled"
import Cell from "../Cell"

export default function Row({ children, index, style, onCheck, checked }) {
  return (
    <Styled.Row style={style}>
      <Cell>
        <input type="checkbox" onChange={() => onCheck(index)} checked={checked} />
      </Cell>
      <Cell width={60}>{index} </Cell>
      {children}
    </Styled.Row>
  )
}
