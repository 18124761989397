import styled from "styled-components"

export const Container = styled.div``

export const Box = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: ${({ theme }) => theme.zIndexes.z10};
  /* transition: all 200ms; */
  top: ${({ styles }) => styles.top + "px"};
  left: ${({ styles }) => styles.left + "px"};
  width: ${({ styles }) => styles.width + "px"};
  height: ${({ styles }) => styles.height + "px"};
  border: ${({ styles, theme }) => `${styles.borderWidth}px solid ${theme.colors.select}`};
  border-left: ${({ styles }) => (styles.numWidth ? "none" : `${styles.borderWidth}px solid ${styles.color}`)};
  background-color: rgba(58, 152, 186, 0.05);
  pointer-events: none;

  div {
    width: ${({ styles }) => styles.numWidth + 1 + "px"};
    height: ${({ styles }) => styles.height - 2 + "px"};
    background-color: rgba(14, 165, 233, 0.3);
    /* transition: all 200ms; */
  }
`
