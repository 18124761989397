import { getColumnIndexById } from "./helpers"
import { COLUMN_IDS, COLUMN_STATUSES } from "./constants"
import { buildCategoryId, isParent } from "./helpers"

export function updateTableWithFormulas(tableData, lastUpdateData = {}) {
  const { rows, columns } = tableData

  rows.forEach((_row, rowIndex) => {
    calculatePositionNumber(rows, rowIndex, columns)
    calculateQuantity(rows, rowIndex, lastUpdateData, columns)
    calculateQuantityAbsolut(rows, rowIndex, lastUpdateData, columns)
    calculateTotalCosts(rows, rowIndex, columns)
  })

  return { rows: rows }
}

function calculateTotalCosts(rows, rowIndex, columns) {
  let row = rows[rowIndex]
  let totalCostIndex = getColumnIndexById(COLUMN_IDS.TOTAL_COST, columns)
  let unitCostIndex = getColumnIndexById(COLUMN_IDS.UNIT_COST, columns)

  if (isParent(rowIndex, rows, columns)) {
    for (let i = 0; i < row.length; i++) {
      if (columns[i].id.startsWith("category_")) {
        row[i] = { v: 0, e: 0 }
      }
    }
    row[totalCostIndex] = { v: 0, e: 0 }
    row[unitCostIndex] = { v: null, e: 0 }
  } else {
    let category = row[getColumnIndexById(COLUMN_IDS.CATEGORY, columns)].v || ""
    let dynamicColumnUnitCostIndex = getColumnIndexById(`category_${buildCategoryId(category)}_unit`, columns)
    let dynamicColumnTotalCostIndex = getColumnIndexById(`category_${buildCategoryId(category)}_total`, columns)
    let quantityAbolut = row[getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)].v
    let unitCost = row[unitCostIndex].v
    let totalCost = quantityAbolut * unitCost

    row[totalCostIndex] = { v: totalCost, e: 0 }

    row.forEach((cell, index) => {
      if (columns[index].status === COLUMN_STATUSES.DYNAMIC) {
        row[index] = { v: null, e: 0 }
      }
    })

    if (dynamicColumnUnitCostIndex !== -1) {
      row[dynamicColumnTotalCostIndex] = { v: totalCost, e: 0 }
      row[dynamicColumnUnitCostIndex].v = row[unitCostIndex].v
    }

    updateParents(
      rows,
      rowIndex,
      totalCost,
      totalCostIndex,
      unitCostIndex,
      dynamicColumnTotalCostIndex,
      dynamicColumnUnitCostIndex,
      columns
    )
  }
}

// uodate parents category total/unit cost and overall total/unit cost
function updateParents(
  rows,
  rowIndex,
  totalCost,
  totalCostIndex,
  unitCostIndex,
  dynamicColumnTotalCostIndex,
  dynamicColumnUnitCostIndex,
  columns
) {
  let row = rows[rowIndex]
  let parentRowIndex = row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v
  if (parentRowIndex === -1) return
  let parentRow = rows[parentRowIndex]
  let parentTotalCost = rows[parentRowIndex][totalCostIndex].v

  if (dynamicColumnUnitCostIndex !== -1) {
    let parentCategoryTotalCost = rows[parentRowIndex][dynamicColumnTotalCostIndex].v
    parentRow[dynamicColumnTotalCostIndex] = { v: parentCategoryTotalCost + totalCost, e: 0 }
  }
  parentRow[totalCostIndex] = { v: parentTotalCost + totalCost, e: 0 }
  updateParents(
    rows,
    parentRowIndex,
    totalCost,
    totalCostIndex,
    unitCostIndex,
    dynamicColumnTotalCostIndex,
    dynamicColumnUnitCostIndex,
    columns
  )
}

function calculateQuantity(rows, rowIndex, lastUpdateData, columns) {
  let row = rows[rowIndex]
  if (row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v === -1) {
    row[getColumnIndexById(COLUMN_IDS.QUANTITY, columns)] = { v: null, e: 0 }

    return
  }

  if (
    lastUpdateData.rowIndex === rowIndex &&
    lastUpdateData.columnIndex === Number(getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns))
  ) {
    row[getColumnIndexById(COLUMN_IDS.QUANTITY, columns)] = { v: null, e: 0 }
  }
}

function calculateQuantityAbsolut(rows, rowIndex, lastUpdateData, columns) {
  let row = rows[rowIndex]
  let res
  if (row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v === -1) {
    if (row[getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)].v === null) {
      row[getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)] = { v: 1, e: 0 }
    }

    return
  }

  if (
    !row[getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)].e ||
    (lastUpdateData.rowIndex === rowIndex &&
      lastUpdateData.columnIndex === Number(getColumnIndexById(COLUMN_IDS.QUANTITY, columns)))
  ) {
    let selfQuantity = row[getColumnIndexById(COLUMN_IDS.QUANTITY, columns)].v
    let parentQuantityAbsolute =
      rows[row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v][
        getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)
      ].v
    res = selfQuantity * parentQuantityAbsolute

    row[getColumnIndexById(COLUMN_IDS.QUANTITY_ABSOLUT, columns)] = { v: res || 1, e: 0 }
  }
}

export function calculatePositionNumber(rows, rowIndex, columns) {
  let row = rows[rowIndex]
  let res = ""
  if (rowIndex === 0) {
    res = "1"
  } else if (
    row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v ===
    rows[rowIndex - 1][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v
  ) {
    // check if siblings
    let prevPosition = rows[rowIndex - 1][getColumnIndexById(COLUMN_IDS.POSITION, columns)].v
    let fragments = prevPosition.split(".")
    fragments[fragments.length - 1] = Number(fragments[fragments.length - 1]) + 1
    res = fragments.join(".")
  } else if (row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v === rowIndex - 1) {
    // check if first child
    let parentPosition = rows[rowIndex - 1][getColumnIndexById(COLUMN_IDS.POSITION, columns)].v
    res = parentPosition + ".1"
  } else {
    // is just a child
    let prevSiblingIndex = rowIndex - 1
    while (
      rows[prevSiblingIndex][getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v !==
      row[getColumnIndexById(COLUMN_IDS.PARENT_INDEX, columns)].v
    ) {
      prevSiblingIndex--
    }
    let prevPosition = rows[prevSiblingIndex][getColumnIndexById(COLUMN_IDS.POSITION, columns)].v
    let fragments = prevPosition.split(".")
    fragments[fragments.length - 1] = Number(fragments[fragments.length - 1]) + 1
    res = fragments.join(".")
  }

  row[getColumnIndexById(COLUMN_IDS.POSITION, columns)] = { v: res, e: 0 }
}
