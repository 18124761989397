import * as Styled from "./styled"
import { useState } from "react"
import logo from "../../../assets/icon.png"
import Button from "../../button"
import { useDispatch } from "react-redux"
import { setPage } from "../pagesSlice"
import { PAGES } from "../constants"
import { ReactComponent as BackIcon } from "../../../icons/arrow-left.svg"
import * as API from "../../../app/api"
import { useSnackbar } from "notistack"
import { changeLoaderVisibility } from "../../loader/loaderSlice"

export default function Register() {
  const [name, setName] = useState("David")
  const [phone, setPhone] = useState("4234242424242")
  const [country, setCountry] = useState("Gergia")
  const [email, setUpEmail] = useState("david@gmail.com")
  const [password, setUpPassword] = useState("12345678")

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  function navigateToLogin() {
    dispatch(setPage({ page: PAGES.LOGIN }))
  }

  function navigateToInitial() {
    dispatch(setPage({ page: PAGES.INITIAL }))
  }

  function register() {
    dispatch(changeLoaderVisibility({ show: true }))
    // dispatch(setPage({ page: PAGES.HOME })); //temporary
    API.register({ name, phone, country, email, password })
      .then((res) => {
        dispatch(setPage({ page: PAGES.DASHBOARD }))
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data.message, {
          variant: "error",
        })
      })
  }

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="heading-text">Sign up new account</div>
        <Styled.Inputs>
          <>
            <Styled.Input type="text" placeholder={"Name"} value={name} onChange={(e) => setName(e.target.value)} />
            <Styled.Input
              type="number"
              placeholder={"Phone Number"}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Styled.Input
              type="email"
              placeholder={"Email Address"}
              value={email}
              onChange={(e) => setUpEmail(e.target.value)}
            />
            <Styled.Input
              type="text"
              placeholder={"Country"}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
            <Styled.Input
              type="password"
              placeholder={"Password"}
              value={password}
              onChange={(e) => setUpPassword(e.target.value)}
            />
          </>
        </Styled.Inputs>
        <div className="submit">
          <Button title={"Sign Up"} onClick={register} />
        </div>
        <div className="have-account">
          <div>
            <span>If you have an account</span>
            <span onClick={navigateToLogin}>Sign In</span>
          </div>
          <BackIcon onClick={navigateToInitial} />
        </div>
      </Styled.Container>
    </Styled.Wrapper>
  )
}
