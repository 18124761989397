import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchAllProjectsMeta } from "../../app/api"

export const fetchAllProjects = createAsyncThunk("projects/fetchAllProjects", async (_, thunkAPI) => {
  const response = await fetchAllProjectsMeta()

  return response
})

const initialState = {
  activeProjectId: null,
  all: [],
  loading: false,
  error: null,
}

const projectsSlice = createSlice({
  name: "projects",
  initialState: initialState,
  reducers: {
    createProject(state, action) {
      let { projectId, meta } = action.payload
      state.all.push({
        id: projectId,
        meta,
      })
    },
    setActiveProjectId(state, action) {
      let { id } = action.payload
      state.activeProjectId = id
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.all = action.payload
        state.loading = false
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.loading = true
          state.error = null
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false
          state.error = action.error.message
        }
      )
  },
})

export function selectAllProjects(state) {
  return state.projects
}

export function selectActiveProjectId(state) {
  return state.projects.activeProjectId
}

export function selectActiveProject(state) {
  const activeProjectId = state.projects.activeProjectId

  return state.projects.all.find((project) => project.id === activeProjectId)
}

export const { createProject, setActiveProjectId } = projectsSlice.actions

export default projectsSlice.reducer
