import styled from "styled-components"
import "simplebar-react/dist/simplebar.min.css"

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
`

export const Main = styled.div`
  flex-grow: 1;
  overflow: hidden;
`

export const Footer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 16px;
  height: 30px;
  width: ${({ theme, $isExtended }) =>
    $isExtended ? `calc(100vw - 32px - ${theme.width.extended})` : `calc(100vw - 32px - ${theme.width.full})`};
  transition: all 200ms;
  border-radius: 8px 8px 0px 0px;
  background: #fff;
  box-shadow: 0px -9px 24px 0px rgba(0, 0, 0, 0.06);
`
